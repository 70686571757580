import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import draftToHtml from 'draftjs-to-html';

const isJson = (data) => {
  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
};

const getTitle = (form) => {
  switch (form) {
    case 'course': return 'Регистрация на пробное занятие';
    case 'master': return 'Регистрация к специалисту';
    case 'events': return 'Регистрация на мероприятие';
    case 'mc': return 'Регистрация на мастер-класс';
    default: return null;
  }
};

const openForm = (form, items) => {
  if (form) {
    document.querySelector('.form-container-container-caption h3').innerText = getTitle(form);
    document.querySelector('.form-modal').classList.toggle('hidden', false);
    document.querySelector('.form-modal .sign-up').setAttribute('items', items);
    document.querySelector('.form-modal .sign-up').setAttribute('form', form);
  }
};

const getURLParam = (name) => {
  const paramRegex = new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`);
  const variable = paramRegex.exec(window.location.search);
  if (variable) return decodeURIComponent(variable[1]);
  return undefined;
};

const initURLFeatures = () => {
  const form = getURLParam('form');
  const items = (getURLParam('items') ?? '').split(/_/g);
  openForm(form, items);

  const saveUtmMarks = () => {
    const utmSource = getURLParam('utm_source');
    if (utmSource) {
      localStorage.setItem('utm_source', utmSource);
    }
    const utmTerm = getURLParam('utm_term');
    if (utmTerm) {
      localStorage.setItem('utm_term', utmTerm);
    }
    const utmContent = getURLParam('utm_content');
    if (utmContent) {
      localStorage.setItem('utm_content', utmContent);
    }
    const utmCampaign = getURLParam('utm_campaign');
    const utmCmpaign = getURLParam('utm_cmpaign');
    if (utmCampaign ?? utmCmpaign) {
      localStorage.setItem('utm_campaign', utmCampaign ?? utmCmpaign);
    }
    const utmMedium = getURLParam('utm_medium');
    if (utmMedium) {
      localStorage.setItem('utm_medium', utmMedium);
    }
  };

  saveUtmMarks();
};

function handleFocus() {
  this.classList.toggle('error', false);
}

// Initialize and add the map
function initMap() {
  // The location of Uluru
  const uluru = { lat: 51.109147679363154, lng: 17.0276309691912 };
  // The map, centered at Uluru
  const map = new google.maps.Map(document.getElementById('map'), {
    zoom: 15,
    center: uluru,
    mapTypeControl: false,
    streetViewControl: false,
  });
  // The marker, positioned at Uluru
  const marker = new google.maps.Marker({
    position: uluru,
    map,
    title: 'Psie Budy 18/19, 50-080 Wrocław, Poland',
  });
}

window.initMap = initMap;

const apiUrl = 'https://courses-crm.com/api/v1';
const token = 'eyJhbGciOiJIUzI1NiJ9.eyJwZXJtaXNzaW9ucyI6W3siYXV0aG9yaXR5IjoiRk9STVNfUkVBRCJ9LHsiYXV0aG9yaXR5IjoiU0FMRVNfTEVBRFNfQ1JFQVRFIn0seyJhdXRob3JpdHkiOiJPTkdPSU5HX0NPVVJTRVNfUkVBRCJ9LHsiYXV0aG9yaXR5IjoiVEVBQ0hFUlNfUkVBRCJ9LHsiYXV0aG9yaXR5IjoiVVNFUlNfUkVBRCJ9LHsiYXV0aG9yaXR5IjoiQ09VUlNFU19SRUFEIn1dLCJpYXQiOjE2OTQwMTc1MTMsInN1YiI6ImxhdWdhZWwxOTkxQGdtYWlsLmNvbSIsImV4cCI6MjAwOTYzNjcxMywiaXNzIjoiY291cnNlLXNpLnBsIn0.H6bKAyeW_wa47f14ZtuZAMQwJb8h_HnNMg7EKJxKSXs';

const loadCourses = async () => {
  const response = await fetch(`${apiUrl}/courses?size=200&page=0&companyId=1`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });

  const { content } = await response.json();

  return content;
};

let lowestStatus = null;

const loadLowestStatus = async () => {
  const response = await fetch(`${apiUrl}/sales-leads-statuses?companyId=1`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });

  const data = await response.json();

  lowestStatus = data.reduce((pV, cV) => {
    if (pV === null) {
      return cV;
    }

    if (cV.sortOrder < pV.sortOrder) {
      return cV;
    }

    return pV;
  }, null);
};

loadLowestStatus();

const initForm = (courses) => {
  const getList = (form) => {
    switch (form) {
      case 'course': return courses;
      case 'master': return ['Логопед'];
      case 'events': return [
        'Зимний лагерь',
        'Гейминг встреча',
        'Мафия',
      ];
      case 'mc': return [
        'Академический рисунок это не скучно!',
        'Робототехника Lego: мой первый робот',
        'Рождественский венок своими руками',
        'Лепка из скульптурного пластилина: Создай своих супергероев',
        'Зимний лагерь',
        'Вебинар',
        'Робототехника Lego: мой первый робот + Подарок своими руками',
        'Зимний лагерь Swojczyce Olimpia Port',
        'Супер Крутая Вытечка',
      ];
      default: return null;
    }
  };
  const getType = (form) => {
    switch (form) {
      case 'course': return 'Пробное занятие: ';
      case 'master': return 'Специалист: ';
      case 'events': return 'Мероприятие: ';
      case 'mc': return 'Событие: ';
      default: return null;
    }
  };

  document.querySelectorAll('.form input').forEach((item) => item.addEventListener('focus', handleFocus));

  const phoneInput = document.querySelector('.form input#phone');

  const iti = intlTelInput(phoneInput, {
    separateDialCode: true,
    nationalMode: true,
    initialCountry: 'auto',
    utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
    geoIpLookup: (callback) => {
      fetch('https://ipapi.co/json')
        .then((res) => res.json())
        .then((data) => {
          callback(data.country_code);
        })
        .catch(() => {
          callback('us');
        });
    },
  });

  const cleanUp = () => {
    document.querySelector('#firstname').value = '';
    document.querySelector('#phone').value = '';
  };

  const getDescription = (selected, form) => {
    if (form === 'course') {
      return `${getType(form)}${getList(form)
        .filter(
          (item) => selected.includes(item.id.toString()),
        ).map((course) => course.name)
        .join(';')}`;
    }

    return `${getType(form)}${getList(form)
      .filter(
        (_, index) => selected.includes(`${index + 1}`),
      )
      .join(';')}`;
  };

  const handleFormSubmit = async (event) => {
    const form = event.target.getAttribute('form');
    const selected = event.target.getAttribute('items').split(/_/g);

    const firstname = document.querySelector('#firstname').value;
    const email = document.querySelector('#email').value;

    const firstnameError = firstname.trim().length === 0;
    const emailError = !/^(\w+?\.??)*\w+?@([\w-]+?\.)+\w{2,}$/gi.test(email.trim());
    const phoneError = !iti.isValidNumber();

    if (firstnameError) {
      document.querySelector('#firstname').classList.toggle('error', true);
    }

    if (phoneError) {
      document.querySelector('#phone').classList.toggle('error', true);
    }

    if (emailError) {
      document.querySelector('#email').classList.toggle('error', true);
    }

    if (!firstnameError && !phoneError && !emailError) {
      const response = await fetch(`${apiUrl}/sales-leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          firstname,
          lastname: '',
          phone: iti.getNumber().replace(/[+\s]/gi, ''),
          email,
          description: getDescription(selected, form),
          childFirstname: '',
          childLastname: '',
          utmSource: localStorage.getItem('utm_source') ?? '',
          utmTerm: localStorage.getItem('utm_term') ?? '',
          utmContent: localStorage.getItem('utm_content') ?? '',
          utmCampaign: localStorage.getItem('utm_campaign') ?? '',
          utmMedium: localStorage.getItem('utm_medium') ?? '',
          other: '{}',
          salesLeadsStatus: lowestStatus,
          companyId: 1,
        }),
        method: 'POST',
      });

      if (response.ok) {
        document.querySelector('.form-modal').classList.toggle('hidden', true);
        document.querySelector('.success-message').classList.toggle('hidden', false);
        localStorage.removeItem('utm_source');
        localStorage.removeItem('utm_term');
        localStorage.removeItem('utm_content');
        localStorage.removeItem('utm_campaign');
        localStorage.removeItem('utm_medium');

        cleanUp();
      } else {
        document.querySelector('.form-modal').classList.toggle('hidden', true);
        document.querySelector('.unsuccess-message').classList.toggle('hidden', false);
      }
    }
  };

  document.querySelector('.form .sign-up')?.addEventListener('click', handleFormSubmit);

  const hideSignUpModal = () => {
    document.querySelector('.form-modal').classList.toggle('hidden', true);
    cleanUp();
  };
  document.querySelector('.form-modal-body-close-button')?.addEventListener('click', hideSignUpModal);
};

const handleFeedbackSliderRightButtonClick = () => {
  const visibleContainer = document.querySelector('.feedback-slider-visible-container');
  const { width: visibleContainerWidth } = visibleContainer.getBoundingClientRect();
  const visibleContainerScroll = visibleContainer.scrollLeft;
  const { width: slideWidth } = document.querySelector('.feedback-slide').getBoundingClientRect();
  const slideWidthWithMargin = slideWidth + 30;

  const { width: screenWidth } = document.querySelector('body').getBoundingClientRect();

  const slidesCount = document.querySelectorAll('.feedback-slide').length;

  const maxScroll = screenWidth < 768
    ? slideWidthWithMargin * (slidesCount - 1)
    : slideWidthWithMargin * slidesCount - visibleContainerWidth;

  const newPossibleScroll = visibleContainerScroll
    + (screenWidth < 768 ? slideWidthWithMargin : visibleContainerWidth);

  const newScroll = newPossibleScroll > maxScroll ? maxScroll : newPossibleScroll;

  visibleContainer.scrollTo({ left: newScroll, behavior: 'smooth' });
};

const handleFeedbackSliderLeftButtonClick = () => {
  const visibleContainer = document.querySelector('.feedback-slider-visible-container');
  const { width: visibleContainerWidth } = visibleContainer.getBoundingClientRect();
  const visibleContainerScroll = visibleContainer.scrollLeft;
  const { width: screenWidth } = document.querySelector('body').getBoundingClientRect();
  const { width: slideWidth } = document.querySelector('.feedback-slide').getBoundingClientRect();
  const slideWidthWithMargin = slideWidth + 30;

  const minScroll = 0;

  const newPossibleScroll = visibleContainerScroll
    - (screenWidth < 768 ? slideWidthWithMargin : visibleContainerWidth);

  const newScroll = newPossibleScroll < minScroll ? minScroll : newPossibleScroll;

  visibleContainer.scrollTo({ left: newScroll, behavior: 'smooth' });
};

const isTouchDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

const initHorizontalSwipe = (swipeArea, onSwipeLeft, onSwipeRight) => {
  if (!swipeArea) {
    return;
  }

  const coords = { x: null, y: null };
  let mouseDown = false;

  const handleMouseDown = () => {
    mouseDown = true;
  };

  const handleMouseMove = (event) => {
    if (mouseDown) {
      coords.x = event.movementX;
      coords.y = event.movementY;
    }
  };

  const handleMouseUp = () => {
    if (Math.abs(coords.y) > Math.abs(coords.x)) {
      mouseDown = false;
      return;
    }

    if (coords.x < 0) {
      onSwipeRight?.();
    }

    if (coords.x > 0) {
      onSwipeLeft?.();
    }

    mouseDown = false;
  };

  const handleTouchStart = (event) => {
    coords.x = event.changedTouches[0].pageX;
    coords.y = event.changedTouches[0].pageY;
  };

  const handleTouchEnd = (event) => {
    if (
      Math.abs(coords.x - event.changedTouches[0].pageX)
      > Math.abs(coords.y - event.changedTouches[0].pageY)
    ) {
      if (coords.x > event.changedTouches[0].pageX) {
        onSwipeRight?.();
      }

      if (coords.x < event.changedTouches[0].pageX) {
        onSwipeLeft?.();
      }
    }

    coords.x = null;
    coords.y = null;
  };

  if (isTouchDevice()) {
    swipeArea.addEventListener('touchstart', handleTouchStart);
    swipeArea.addEventListener('touchend', handleTouchEnd);
  } else {
    swipeArea.addEventListener('mousedown', handleMouseDown);
    swipeArea.addEventListener('mousemove', handleMouseMove);
    swipeArea.addEventListener('mouseup', handleMouseUp);
  }
};

const initTopSlider = () => {
  if (!document.querySelector('.slider')) {
    return;
  }

  let slideInterval;

  const handleSliderLeftButtonClick = () => {
    clearInterval(slideInterval);
    const { width: slideWidth } = document.querySelector('.slide').getBoundingClientRect();
    const maxScroll = (document.querySelectorAll('.slide').length - 1) * slideWidth;
    const visibleContainer = document.querySelector('.slider-visible-container');
    const visibleContainerScroll = visibleContainer.scrollLeft;

    const newPossibleScroll = visibleContainerScroll - slideWidth;

    const newScroll = newPossibleScroll < 0 ? maxScroll : newPossibleScroll;

    const fixedNewScroll = Math.ceil(newScroll / slideWidth) * slideWidth;

    visibleContainer.scrollTo({ left: fixedNewScroll, behavior: 'smooth' });
  };

  const handleSliderRightButtonClick = () => {
    clearInterval(slideInterval);
    const { width: slideWidth } = document.querySelector('.slide').getBoundingClientRect();
    const maxScroll = (document.querySelectorAll('.slide').length - 1) * slideWidth;
    const visibleContainer = document.querySelector('.slider-visible-container');
    const visibleContainerScroll = visibleContainer.scrollLeft;

    const newPossibleScroll = visibleContainerScroll + slideWidth;

    const newScroll = newPossibleScroll > maxScroll ? 0 : newPossibleScroll;

    const fixedNewScroll = Math.ceil(newScroll / slideWidth) * slideWidth;

    visibleContainer.scrollTo({ left: fixedNewScroll, behavior: 'smooth' });
  };

  document.querySelector('.slider-button-left').addEventListener('click', handleSliderLeftButtonClick);
  document.querySelector('.slider-button-right').addEventListener('click', handleSliderRightButtonClick);

  const coords = { x: null, y: null };

  const handleTouchStart = (event) => {
    coords.x = event.changedTouches[0].pageX;
    coords.y = event.changedTouches[0].pageY;
  };

  const handleTouchEnd = (event) => {
    if (
      Math.abs(coords.x - event.changedTouches[0].pageX)
      > Math.abs(coords.y - event.changedTouches[0].pageY)
    ) {
      if (coords.x > event.changedTouches[0].pageX) {
        handleSliderRightButtonClick();
      }

      if (coords.x < event.changedTouches[0].pageX) {
        handleSliderLeftButtonClick();
      }
    }

    coords.x = null;
    coords.y = null;
  };

  const sliderPointsContainer = document.querySelector('.slider-points');
  const visibleContainer = document.querySelector('.slider-visible-container');

  if (isTouchDevice()) {
    visibleContainer.addEventListener('touchstart', handleTouchStart);
    visibleContainer.addEventListener('touchend', handleTouchEnd);
  }

  const slidesAnchors = [];

  document.querySelectorAll('.slide').forEach((item, index) => {
    const slideAnchor = document.createElement('span');
    slideAnchor.addEventListener('click', () => {
      clearInterval(slideInterval);
      visibleContainer.scrollTo({ left: index * item.getBoundingClientRect().width, behavior: 'smooth' });
    });

    sliderPointsContainer.appendChild(slideAnchor);
    slidesAnchors.push(slideAnchor);
  });

  visibleContainer.addEventListener('scroll', () => {
    const slideIndex = visibleContainer.scrollLeft / document.querySelector('.slide').getBoundingClientRect().width;

    if (slideIndex % 1 === 0) {
      slidesAnchors.forEach((item, index) => {
        if (slideIndex === index) {
          item.classList.toggle('active', true);
        }

        if (slideIndex !== index) {
          item.classList.toggle('active', false);
        }
      });
    }
  });

  let slideIndex = 0;

  visibleContainer.scrollTo({
    left: document.querySelector('.slide').getBoundingClientRect().width * slideIndex,
    behavior: 'smooth',
  });

  slideInterval = setInterval(() => {
    slideIndex = (slideIndex + 1) % slidesAnchors.length;

    visibleContainer.scrollTo({
      left: document.querySelector('.slide').getBoundingClientRect().width * slideIndex,
      behavior: 'smooth',
    });
  }, 5000);
};

const initHandlers = () => {
  const hideSuccessMessage = () => {
    document.querySelector('.success-message').classList.toggle('hidden', true);
  };
  document.querySelector('.success-message-body-close-button')?.addEventListener('click', hideSuccessMessage);

  const hideUnSuccessMessage = () => {
    document.querySelector('.unsuccess-message').classList.toggle('hidden', true);
  };
  document.querySelector('.unsuccess-message-body-close-button')?.addEventListener('click', hideUnSuccessMessage);

  const openSignUpModal = (event) => {
    const form = event.target.getAttribute('form') ?? 'course';
    const items = [event.target.getAttribute('items')];
    openForm(form, items);
  };

  document.querySelectorAll('button.sign-up:not([type="submit"])').forEach((button) => {
    button.addEventListener('click', openSignUpModal);
  });

  const handleOpenCloseMenu = () => {
    document.querySelector('.open-menu-button').classList.toggle('open');
    document.querySelector('.nav-data-container').classList.toggle('open');
    document.querySelector('body').classList.toggle('block-scroll');
  };

  document.querySelector('.open-menu-button')?.addEventListener('click', handleOpenCloseMenu);

  const leftFeedbackSliderButton = document.querySelector('.feedback-slider-button-left');
  const rightFeedbackSliderButton = document.querySelector('.feedback-slider-button-right');

  leftFeedbackSliderButton?.addEventListener('click', handleFeedbackSliderLeftButtonClick);
  rightFeedbackSliderButton?.addEventListener('click', handleFeedbackSliderRightButtonClick);

  const feedbacksSliderTouchArea = document.querySelector('.feedback-slider-visible-container');
  initHorizontalSwipe(
    feedbacksSliderTouchArea,
    handleFeedbackSliderLeftButtonClick,
    handleFeedbackSliderRightButtonClick,
  );
};

// faq
function toggleItem() {
  this.parentElement.parentElement.classList.toggle('active');
}

document.querySelectorAll('.faq-list-item-title-icon').forEach((itemButton) => {
  itemButton.addEventListener('click', toggleItem);
});

// courses
const getAgeLabel = (course) => {
  if (
    course.ageFrom !== null && course.ageFrom !== undefined && course.ageFrom !== 0
    && course.ageTo !== null && course.ageTo !== undefined && course.ageTo !== 0
  ) {
    return `${course.ageFrom}-${course.ageTo} лет`;
  }

  if (course.ageFrom !== null && course.ageFrom !== undefined && course.ageFrom !== 0) {
    return `от ${course.ageFrom} лет`;
  }

  if (course.ageTo !== null && course.ageTo !== undefined && course.ageTo !== 0) {
    return `до ${course.ageTo} лет`;
  }

  return null;
};

const loadImage = async (code, destination) => {
  if (!code) {
    return;
  }

  const response = await fetch(`${apiUrl}/files/${code}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  });

  const blob = await response.blob();
  const reader = new FileReader();
  reader.onload = function () {
    destination.src = this.result;
  };
  reader.readAsDataURL(blob);
};

const colors = ['#A0E0C1', '#6DD7E2', '#EF9330', '#9E42CE'];
const getBackgroundColor = () => colors[Math.ceil(Math.random() * 4)];

const handleCourseCardClick = (
  course,
  courseImage,
  courseTitleImage,
  color = getBackgroundColor(),
) => (event) => {
  const courseView = document.querySelector('.course-view');
  courseView.classList.toggle('hidden', false);

  const items = event ? event.target.getAttribute('items') : '7';

  const courseViewTitle = courseView.querySelector('.course-view-title');
  const courseViewDescription = courseView.querySelector('.course-view-description');
  const courseViewImage = courseView.querySelector('.course-view-image');
  const courseViewAge = courseView.querySelector('.course-view-age');
  const courseViewPrice = courseView.querySelector('.course-view-footer-price');
  const courseViewTitleImageContainer = courseView.querySelector('.course-view-body-close-container');
  const courseViewTitleImage = courseView.querySelector('.course-view-header-image');
  const courseViewSignUp = courseView.querySelector('.sign-up');

  courseViewTitle.innerHTML = course.name;
  if (isJson(course.description)) {
    courseViewDescription.innerHTML = draftToHtml(JSON.parse(course.description));
  } else {
    courseViewDescription.innerHTML = course.description.replace(/\n/gi, '<br>');
  }
  courseViewImage.src = courseImage.src;
  courseViewSignUp.setAttribute('items', items);
  if (courseTitleImage) {
    courseViewTitleImage.src = courseTitleImage.src;
  } else {
    loadImage(JSON.parse(course.other).headerImage, courseViewTitleImage);
  }
  courseViewAge.innerText = getAgeLabel(course);
  courseViewTitleImageContainer.style.backgroundColor = color;
  courseViewPrice.innerText = `Стоимость месяца занятий ${JSON.parse(course.other).price} PLN`;

  document.querySelector('body').classList.toggle('block-scroll', true);
};

const initCoursesList = (courses) => {
  const coursesList = document.querySelector('.courses-list');

  if (coursesList) {
    courses.forEach((course) => {
      const courseTitleImage = document.createElement('img');
      courseTitleImage.classList.add('course-title-img');
      loadImage(JSON.parse(course.other).headerImage, courseTitleImage);

      const color = getBackgroundColor();

      const courseTitleDiv = document.createElement('div');
      courseTitleDiv.classList.add('course-title');
      courseTitleDiv.style.backgroundColor = color;
      courseTitleDiv.appendChild(courseTitleImage);

      const courseImage = document.createElement('img');
      courseImage.classList.add('course-img');
      loadImage(JSON.parse(course.other).image, courseImage);

      const courseBodyTitle = document.createElement('h3');
      courseBodyTitle.classList.add('course-body-title');
      courseBodyTitle.innerText = course.name ?? '';

      const courseBodyDescription = document.createElement('p');
      courseBodyDescription.classList.add('course-body-description');
      courseBodyDescription.innerText = course.shortDescription ?? '';

      const courseBodyButtonMore = document.createElement('button');
      courseBodyButtonMore.classList.add('course-body-buttons-more');
      courseBodyButtonMore.innerText = 'подробнее';
      courseBodyButtonMore.addEventListener('click', handleCourseCardClick(course, courseImage, courseTitleImage, color));
      courseBodyButtonMore.setAttribute('items', course.id);

      const courseBodyButtonSignUp = document.createElement('button');
      courseBodyButtonSignUp.classList.add('course-body-buttons-signup', 'sign-up');
      courseBodyButtonSignUp.innerText = 'записаться';
      courseBodyButtonSignUp.setAttribute('items', course.id);

      const courseBodyButtons = document.createElement('div');
      courseBodyButtons.classList.add('course-body-buttons');
      courseBodyButtons.appendChild(courseBodyButtonMore);
      courseBodyButtons.appendChild(courseBodyButtonSignUp);

      const courseBody = document.createElement('div');
      courseBody.classList.add('course-body');
      courseBody.appendChild(courseBodyTitle);
      courseBody.appendChild(courseBodyDescription);
      courseBody.appendChild(courseBodyButtons);

      const ageLabel = getAgeLabel(course);
      const courseAgeData = document.createElement('span');
      courseAgeData.classList.add('course-age-data');
      courseAgeData.innerText = ageLabel;

      const courseAge = document.createElement('div');
      courseAge.classList.add('course-age');
      courseAge.appendChild(courseAgeData);

      const courseDiv = document.createElement('div');
      courseDiv.classList.add('course');
      courseDiv.appendChild(courseTitleDiv);
      courseDiv.appendChild(courseImage);
      courseDiv.appendChild(courseBody);

      if (ageLabel) {
        courseDiv.appendChild(courseAge);
      }

      coursesList.appendChild(courseDiv);
    });
  }
};

const initHomePageCourseList = (courses) => {
  const coursesList = document.querySelector('.homepage-courses-list');

  if (!coursesList) {
    return;
  }

  courses.forEach((course, index) => {
    const courseImage = document.createElement('img');
    courseImage.classList.add('course-card-image');
    loadImage(JSON.parse(course.other).image, courseImage);

    const ageContainer = document.createElement('span');
    ageContainer.classList.add('course-card-age');
    ageContainer.innerText = getAgeLabel(course);

    const courseCardTitle = document.createElement('h3');
    courseCardTitle.classList.add('course-card-title');
    courseCardTitle.innerText = course.name;

    const courseCardDescription = document.createElement('p');
    courseCardDescription.classList.add('course-card-description');
    courseCardDescription.innerText = course.shortDescription;

    const courseCardBody = document.createElement('div');
    courseCardBody.classList.add('course-card-body');
    courseCardBody.appendChild(courseCardTitle);
    courseCardBody.appendChild(courseCardDescription);

    const courseCardButtonMore = document.createElement('button');
    courseCardButtonMore.innerText = 'Подробнее';
    courseCardButtonMore.classList.add('course-card-button', 'course-card-button-more');
    courseCardButtonMore.addEventListener('click', handleCourseCardClick(course, courseImage));
    courseCardButtonMore.setAttribute('items', index + 1);

    const courseCardButtonSignUp = document.createElement('button');
    courseCardButtonSignUp.innerText = 'Записаться';
    courseCardButtonSignUp.classList.add('course-card-button', 'course-card-button-sign-up', 'sign-up');
    courseCardButtonSignUp.setAttribute('items', index + 1);

    const courseCardButtonsContainer = document.createElement('div');
    courseCardButtonsContainer.classList.add('course-card-buttons-container');
    courseCardButtonsContainer.appendChild(courseCardButtonMore);
    courseCardButtonsContainer.appendChild(courseCardButtonSignUp);

    const courseCard = document.createElement('div');
    courseCard.classList.add('course-card');
    courseCard.appendChild(courseImage);
    courseCard.appendChild(ageContainer);
    courseCard.appendChild(courseCardBody);
    courseCard.appendChild(courseCardButtonsContainer);

    coursesList.appendChild(courseCard);
  });
};

const initCourseView = () => {
  document.querySelector('.course-view-body-close-button').addEventListener('click', () => {
    const courseView = document.querySelector('.course-view');
    courseView.classList.toggle('hidden', true);
    document.querySelector('body').classList.toggle('block-scroll', false);
  });
};

const initMCView = () => {
  const handleOpenMCView = (headerImage, image, description, age, title, price, after) => (event) => {
    const courseView = document.querySelector('.course-view');

    courseView.querySelector('.course-view-header-image').src = headerImage;
    courseView.querySelector('.course-view-image').src = image;
    courseView.querySelector('.course-view-age').innerText = age;
    courseView.querySelector('.course-view-description').innerHTML = description;
    courseView.querySelector('.course-view-title').innerText = title;
    courseView.querySelector('.course-view-footer-after').innerHTML = after;

    courseView.querySelector('.course-view-image').style.objectFit = 'cover';
    if (event.target.getAttribute('items').split(/_/gi).includes('5')) {
      courseView.querySelector('.course-view-image').style.objectFit = 'contain';
    }
    if (event.target.getAttribute('items').split(/_/gi).includes('6')) {
      courseView.querySelector('.course-view-image').style.objectPosition = '50% 40%';
    }

    courseView.classList.toggle('hidden', false);

    courseView.querySelectorAll('.sign-up').forEach(
      (btn) => btn.setAttribute('form', 'mc'),
    );
    courseView.querySelectorAll('.sign-up').forEach(
      (btn) => btn.setAttribute('items', event.target.getAttribute('items')),
    );
  };

  const handleOpenMCViewFromStart = (headerImage, image, description, age, title, price, after) => ({ form, items }) => {
    const courseView = document.querySelector('.course-view');

    courseView.querySelector('.course-view-header-image').src = headerImage;
    courseView.querySelector('.course-view-image').src = image;
    courseView.querySelector('.course-view-age').innerText = age;
    courseView.querySelector('.course-view-description').innerHTML = description;
    courseView.querySelector('.course-view-title').innerText = title;
    courseView.querySelector('.course-view-footer-after').innerHTML = after;

    courseView.querySelector('.course-view-image').style.objectFit = 'cover';
    if (items.includes('5')) {
      courseView.querySelector('.course-view-image').style.objectFit = 'contain';
    }
    if (items.includes('6')) {
      courseView.querySelector('.course-view-image').style.objectPosition = '50% 40%';
    }

    courseView.classList.toggle('hidden', false);

    courseView.querySelectorAll('.sign-up').forEach(
      (btn) => btn.setAttribute('form', form),
    );
    courseView.querySelectorAll('.sign-up').forEach(
      (btn) => btn.setAttribute('items', items),
    );
  };

  const getMCData = (mc) => ({
    moreButton: mc.querySelector('.mc-body-buttons-more'),
    headerImage: mc.querySelector('.mc-title-img').src,
    image: mc.querySelector('.mc-img').src,
    age: mc.querySelector('.mc-age-data').innerText,
    description: mc.querySelector('.mc-full-description')?.innerHTML,
    title: mc.querySelector('.mc-body-title').innerText,
    price: mc.querySelector('.mc-price').innerText,
    after: mc.querySelector('.mc-after').innerHTML,
  });

  document.querySelectorAll('.mc').forEach((mc) => {
    if (mc.innerHTML === '') {
      return;
    }

    const {
      headerImage,
      image,
      description,
      age,
      title,
      price,
      moreButton,
      after,
    } = getMCData(mc);

    moreButton.addEventListener('click', handleOpenMCView(
      headerImage,
      image,
      description,
      age,
      title,
      price,
      after,
    ));
  });

  const view = getURLParam('view');
  const item = getURLParam('item');

  if (view === 'mc') {
    const {
      headerImage,
      image,
      description,
      age,
      title,
      price,
      after,
    } = getMCData(document.querySelector(`.mc:nth-child(${item})`));
    handleOpenMCViewFromStart(headerImage, image, description, age, title, price, after)({ form: 'mc', items: [item] });
  }
};

const openCourseView = async (courses) => {
  const view = getURLParam('view');
  const item = getURLParam('item');

  if (view === 'course') {
    const course = courses.find((c) => c.id.toString() === item);

    const courseImage = document.createElement('img');
    await loadImage(JSON.parse(course.other).image, courseImage);

    const courseTitleImage = document.createElement('img');
    await loadImage(JSON.parse(course.other).headerImage, courseTitleImage);

    handleCourseCardClick(course, courseImage, courseTitleImage)(undefined, item);
  }
};

// general load page data
const loadPage = async () => {
  const courses = await loadCourses();
  const filteredCourses = courses.filter((course) => course.enabled);
  initURLFeatures();
  initCoursesList(filteredCourses);
  initTopSlider();
  initHomePageCourseList(filteredCourses);
  initHandlers();
  initCourseView();
  initMCView();
  initForm(filteredCourses);
  await openCourseView(filteredCourses);
};

loadPage();
